import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common/Buttons/Button';

export default function SignInButton(): JSX.Element | null {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { t } = useTranslation('translation', { keyPrefix: 'login' });

  if (isAuthenticated) return null;

  return (
    <Button
      id="login"
      onClick={() => loginWithRedirect({ prompt: 'login' })}
      color="primary"
    >
      {t('logIn')}
    </Button>
  );
}
