import { FunctionComponent } from 'react';
import Link from 'next/link';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { ErrorType, useErrorDetail } from 'utilities/errors';

interface Props {
  type: ErrorType;
  customTitle?: string;
  customMessage?: string;
  logoutOnReturn?: boolean;
}

function useLogout() {
  try {
    return useAuth0().logout;
  } catch {
    return noop;
  }
}

export const ErrorScreen: FunctionComponent<Props> = ({
  type,
  customTitle,
  customMessage,
  logoutOnReturn,
}) => {
  const { title, message } = useErrorDetail(type);
  const { t } = useTranslation('translation', {
    keyPrefix: 'errorScreen',
  });
  const logout = useLogout();

  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
              {customTitle || title}
            </h1>
            <p className="mt-2 text-base text-gray-500">
              {customMessage || message}
            </p>
            <div className="mt-6">
              <Link href="/">
                <a
                  href="#"
                  onClick={() =>
                    logoutOnReturn &&
                    logout({ returnTo: window.location.origin })
                  }
                  className="text-base font-medium text-accent-600 hover:text-accent-500"
                >
                  {t('goBackHome')}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

ErrorScreen.defaultProps = { type: ErrorType.ERRORTYPE_UNSPECIFIED };
