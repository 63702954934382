import {
  ArrowUpOnSquareIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import { isMobileSafari } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { usePersistedState } from '../hooks/usePersistedState';

export const InstallationHint: FunctionComponent = () => {
  const [userHasBeenPrompted, setUserHasBeenPrompted] = usePersistedState(
    'pwa-prompted',
    false,
  );
  const { t } = useTranslation('translation', {
    keyPrefix: 'installationHint',
  });

  if (userHasBeenPrompted !== false || !isMobileSafari) return null;

  return (
    <div className="fixed bottom-0 left-0 w-full p-4 flex justify-center z-[50]">
      <div className="flex items-center bg-accent-600 text-white p-4 rounded-full text-center shadow shadow-md text-xs">
        <InformationCircleIcon className="w-5 h-5 inline-block mr-2 shrink-0" />
        <p className="leading-relaxed inline">
          <Trans i18nKey={'mobileSafariHint'} t={t}>
            <ArrowUpOnSquareIcon className="w-5 h-5 inline align-bottom" />
          </Trans>
        </p>
        <XMarkIcon
          className="ml-5 w-5 h-5 inline-block justify-self-end cursor-pointer shrink-0"
          onClick={() => setUserHasBeenPrompted(true)}
        />
      </div>
    </div>
  );
};
