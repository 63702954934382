export class CustomError extends Error {
  status: number;
  statusText: string;
  message: string;
  info?: any;
  translationKey?: string;
  constructor(
    status: number,
    statusText: string,
    message: string,
    info?: any,
    translationKey?: string,
  ) {
    super(message);
    this.status = status;
    this.statusText = statusText;
    this.message = message;
    this.info = info;
    this.translationKey = translationKey;
  }
}
