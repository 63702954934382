import React, { FunctionComponent } from 'react';
import { classNames } from '../../utilities/classNames';

interface Props {
  size?: number;
  color?: string;
  thickness?: number;
  className?: string;
  progress?: string;
}

export const LoadingIndicator: FunctionComponent<Props> = ({
  size = 16,
  color,
  thickness,
  className,
  progress,
}) => {
  return (
    <span
      className={classNames(className, 'flex justify-center items-center ')}
      data-cy="loading-indicator"
    >
      <div className="relative flex justify-center items-center">
        <span
          className={classNames(
            `animate-spin rounded-full border-${color}`,
            `h-${size} w-${size} border-b-${
              thickness ? thickness : Math.ceil(size / 8) * 2
            }`,
          )}
        />
        {progress ? (
          <span
            style={{ fontSize: size - 2 }}
            className={classNames(`absolute font-medium text-${color}`)}
          >
            {progress + '%'}
          </span>
        ) : null}
      </div>
    </span>
  );
};

LoadingIndicator.defaultProps = {
  color: 'accent-600',
};
