import '@styles/globals.css';
import 'tailwindcss/tailwind.css';
import { Auth0Provider } from '@auth0/auth0-react';
import Head from 'next/head';
import { I18nextProvider } from 'react-i18next';
import { install as installResizeObserver } from 'resize-observer';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import { NotificationWrapper } from '../context/notificationContext';
import { ErrorPopupWrapper } from '../context/errorPopupContext';
import { ConfirmPopupWrapper } from '../context/confirmPopupContext';
import i18n from '../i18n';
import { InstallationHint } from '../components/InstallationHint';
import ErrorBoundary from '../components/Common/ErrorBoundary';
import fromEntries from '../utilities/fromEntries';
import Auth0InitializationWrapper from 'auth/Auth0InitializationWrapper';
import { AppWrapper } from 'context/appContext';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Application({ Component, pageProps }): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    if (router.query.code || router.query.state) {
      void router.replace(
        {
          query: fromEntries(
            Object.entries(router.query).filter(
              (entry) => entry[0] !== 'code' && entry[0] !== 'state',
            ),
          ),
        },
        undefined,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const isLessonRequestForm = useMemo(
    () => router.pathname.match(/embeddable-lesson-request-form/),
    [router.pathname],
  );

  useEffect(() => {
    if (isLessonRequestForm) return;
    const freshworksSetupScript = document.createElement('script');
    freshworksSetupScript.innerHTML = `
    window.fwSettings={
      'widget_id':202000000204
      };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
    `;

    const freshworksExternalScript = document.createElement('script');
    freshworksExternalScript.src =
      'https://euc-widget.freshworks.com/widgets/202000000204.js';
    freshworksExternalScript.async = true;
    freshworksExternalScript.defer = true;

    document.body.appendChild(freshworksSetupScript);
    document.body.appendChild(freshworksExternalScript);

    return () => {
      document.body.removeChild(freshworksSetupScript);
      document.body.removeChild(freshworksExternalScript);
    };
  }, [isLessonRequestForm]);

  return (
    <>
      <Head>
        <title>AdminDrive</title>
      </Head>
      <I18nextProvider i18n={i18n}>
        <ToggleableAuthAndAppWrapper auth={!isLessonRequestForm}>
          <ErrorBoundary>
            <NotificationWrapper>
              <ErrorPopupWrapper>
                <ConfirmPopupWrapper>
                  <Component {...pageProps} />
                </ConfirmPopupWrapper>
              </ErrorPopupWrapper>
            </NotificationWrapper>
            <InstallationHint />
          </ErrorBoundary>
        </ToggleableAuthAndAppWrapper>
      </I18nextProvider>
    </>
  );
}

/**
 * Wraps the Children in the usual auth provider or the re-captcha environment for the lesson requests
 * @param auth: is auth enabled (= ReCaptcha Disabled)
 * @returns the wrapped components
 */
const ToggleableAuthAndAppWrapper: FunctionComponent<{
  auth: boolean;
}> = ({ children, auth }) => {
  let hostname = ''; // note: window will definitely be defined once the component is actually mounted, but not during SSR

  if (typeof window !== 'undefined') {
    if (!window.ResizeObserver) installResizeObserver();
    hostname = window.location.origin;
  }

  if (auth) {
    return (
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
        redirectUri={hostname}
        cacheLocation="localstorage"
      >
        <Auth0InitializationWrapper>
          <AppWrapper>{children}</AppWrapper>
        </Auth0InitializationWrapper>
      </Auth0Provider>
    );
  }
  return <ReCaptchaProvider useEnterprise>{children}</ReCaptchaProvider>;
};

export default Application;
