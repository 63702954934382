// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn:
    SENTRY_DSN ||
    'https://ccf91ceb4bce46ec8ed6242ab98b36d0@o1151760.ingest.sentry.io/6230678',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment:
    process.env.NEXT_PUBLIC_RELEASE_ENVIRONMENT === 'production'
      ? 'production'
      : 'staging',
  logLevels: ['debug'],
  tracesSampleRate: 1.0,
  enabled: !!process.env.NEXT_PUBLIC_RELEASE_ENVIRONMENT,
});
