import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonThemeColor } from '../components/Common/Buttons/Button';
import { DynamicPopup } from '../components/InfoScreens/DynamicPopup';

export interface ConfirmPopupInfo {
  title?: string | null;
  message: string | null;
  icon?: JSX.Element;
  okText?: string | null;
  accentColor?: ButtonThemeColor;
}

interface ConfirmPopupContextState {
  confirm: (n: ConfirmPopupInfo) => Promise<void>;
}

const ConfirmPopupContext = createContext<ConfirmPopupContextState>(undefined);

export function ConfirmPopupWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const [confirmInfo, setConfirmInfo] = useState<ConfirmPopupInfo | null>(null);
  const [confirmResolve, setConfirmResolve] = useState<Function | null>(null);
  const [confirmReject, setConfirmReject] = useState<Function | null>(null);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  const { t } = useTranslation('translation', { keyPrefix: 'confirmPopup' });

  function confirm(info: ConfirmPopupInfo) {
    setConfirmInfo(info);
    setPopupOpen(true);
    return new Promise<void>((resolve, reject) => {
      setConfirmResolve(() => resolve);
      setConfirmReject(() => reject);
    });
  }

  const sharedState: ConfirmPopupContextState = {
    confirm,
  };

  return (
    <ConfirmPopupContext.Provider value={sharedState}>
      {children}
      <DynamicPopup
        open={popupOpen}
        onClose={() => {
          confirmReject?.();
          setPopupOpen(false);
        }}
        onOk={() => {
          confirmResolve?.();
          setPopupOpen(false);
        }}
        title={t('confirm')}
        {...confirmInfo}
      />
    </ConfirmPopupContext.Provider>
  );
}

export function useConfirmPopupContext() {
  return useContext(ConfirmPopupContext);
}
