import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

/**
 * useState hook that is persisted in localstorage
 *
 * **source**: https://www.30secondsofcode.org/react/s/use-persisted-state
 *
 * **modified by**: @jonasfeld
 * @param key localstorage key
 * @param defaultValue
 * @returns [state, setState], just like normal useState
 */
export function usePersistedState<T>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(); // don't use default value, since the value should only be interpretable once local storage has been queried
  const keyRef = useRef(key);

  // initialize the localstorage value: try to find persisted value,
  // and if there is none, initialize the localstorage to the provided default
  useEffect(() => {
    if (!keyRef.current) return;
    if (key == null) return;
    try {
      const storedValueRaw = localStorage.getItem(key);
      const storedValue = JSON.parse(
        storedValueRaw ?? JSON.stringify(undefined),
      ) as T | undefined;
      if (storedValue != null) {
        setValue(storedValue);
      } else localStorage.setItem(key, JSON.stringify(defaultValue));
    } catch {
      setValue(defaultValue);
    }
  }, [keyRef.current]);

  // every time the value gets changed, persist the new value to localstore
  useEffect(() => {
    if (!keyRef.current) return;
    try {
      localStorage.setItem(keyRef.current, JSON.stringify(value));
    } catch {}
  }, [value, keyRef.current]);

  // every time the key gets changed, remove the value at the old key location
  // and save the value to the new key location
  useEffect(() => {
    const lastKey = keyRef.current;
    if (key !== lastKey) {
      try {
        if (key) localStorage.setItem(key, JSON.stringify(value));
        keyRef.current = key;
        if (lastKey) localStorage.removeItem(lastKey);
      } catch {}
    }
  }, [key]);

  return [value, setValue];
}
