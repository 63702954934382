/**
 * combines the attributes of the queries object that are not null or undefined into the form ?ab=cd&ef=gh&... for url-queries
 * @returns the queries in the specified form or '' if no attributes have non-nullish values
 * @deprecated
 */
export function queries(queries?: QueryObject) {
  if (!queries) return '';
  let r = '';
  for (const k in queries) {
    if (queries[k] != null) {
      r += (r ? '&' : '') + k + '=' + queries[k];
    }
  }
  return r && '?' + r;
}

/**
 * Appends all non-nullish values of the queries object to the URLSearchParams of the given URL.
 */
export function appendQueries(url: URL, queries?: QueryObject) {
  if (!queries) return;
  for (const k in queries) {
    if (queries[k] != null) {
      url.searchParams.append(k, String(queries[k]));
    }
  }
}

export type QueryObject = {
  [key: string]: string | number | boolean | string[] | undefined | null;
};
