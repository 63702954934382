import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSWR from 'swr';
import { useAuth0 } from '@auth0/auth0-react';
import { Organization, Student, User } from '@tr-types/backend-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { HttpEndpoints } from 'data/httpEndpoints';
import authenticatedFetcher from 'data/authenticatedFetcher';
import authenticatedDownloader from 'data/authenticatedDownloader';

export enum UserType {
  STUDENT = 'STUDENT',
  EMPLOYEE = 'EMPLOYEE',
  BOTH = 'BOTH',
}

interface AppContextState<T = User & Student> {
  // The logged in user
  user: T;
  userType: UserType;
  // The logged in user's ID (=auth0 id)
  userId: string;
  // Re-fetches the logged in user and their organization.
  refreshState: () => void;

  // The logged in user's organization
  organization: Organization;
  // Organization ID
  organizationId: string;

  // Url for displaying the organizations custom logo
  organizationLogoUrl?: string;

  // Whether the user is still being loaded
  isLoading: boolean;
  // Whether the user is authenticated
  isAuthenticated: boolean;
}

const AppContext = createContext<AppContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export function AppWrapper({ children }: Props): JSX.Element {
  const {
    user: { sub: id },
    isLoading,
    isAuthenticated,
  } = useAuth0();

  const {
    data: employee,
    isValidating: isValidatingEmployee,
    mutate: mutateEmployeeFetch,
  } = useSWR<User>(
    () => !student && HttpEndpoints.UserEndpoints.getUserById(id),
    authenticatedFetcher,
    {
      fallbackData: { role: { permissions: {} } } as User,
      dedupingInterval: 1000 * 60 * 60 * 2, // 2 hours
      revalidateOnFocus: false,
    },
  );

  const {
    data: student,
    isValidating: isLoadingStudent,
    mutate: mutateStudentFetch,
  } = useSWR<Student>(
    () => HttpEndpoints.StudentEndpoints.getStudentById(id),
    authenticatedFetcher,
    {
      fallbackData: {} as Student,
      revalidateOnFocus: false,
    },
  );

  const user: Student & User = (employee || student) as Student & User;

  const {
    data: organization,
    isValidating: isValidatingOrganization,
    mutate: mutateOrganizationFetch,
  } = useSWR<Organization>(
    () =>
      HttpEndpoints.OrganizationEndpoints.getOrganizationById(
        user?.organization?.id,
      ),
    authenticatedFetcher,
    {
      fallbackData: user?.organization,
      dedupingInterval: 1000 * 60 * 60 * 2, // 2 hours
      revalidateOnFocus: false,
    },
  );

  const { i18n } = useTranslation();
  const i18nextLanguage = i18n.language;

  useEffect(() => {
    dayjs.locale(i18nextLanguage);
  }, [i18nextLanguage]);

  const [organizationLogoUrl, setOrganizationLogoUrl] = useState<string>(null);

  const updateOrganizationLogo = useCallback(async () => {
    if (!organization?.usesCustomLogo) {
      setOrganizationLogoUrl(null);
      return;
    }

    // fetch logo and create URL
    try {
      const blob = await authenticatedDownloader(
        HttpEndpoints.OrganizationEndpoints.getOrganizationLogo(
          organization?.id,
        ),
      );
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(blob);
      setOrganizationLogoUrl(imageUrl);
    } catch (e) {
      setOrganizationLogoUrl(null);
    }
  }, [organization?.usesCustomLogo, organization?.id]);

  useEffect(() => {
    updateOrganizationLogo();
  }, [organization?.usesCustomLogo, updateOrganizationLogo]);

  const userType = useMemo(() => {
    if (!employee?.id && !student?.id) {
      return null;
    }
    return employee?.id ? UserType.EMPLOYEE : UserType.STUDENT;
  }, [employee, student]);

  const sharedState: AppContextState = {
    organization,
    organizationId: organization?.id,
    user: user,
    userId: user?.id,
    userType,
    isLoading:
      isLoading ||
      ((isValidatingEmployee || isLoadingStudent) && !user?.id) ||
      (isValidatingOrganization && !organization.id),
    isAuthenticated,
    organizationLogoUrl,
    refreshState: async () => {
      await mutateEmployeeFetch();
      await mutateStudentFetch();
      await updateOrganizationLogo();
      await mutateOrganizationFetch();
    },
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

export function useAppContext<T extends UserType = UserType.EMPLOYEE>(
  userType?: T,
): AppContextState<
  T extends UserType.STUDENT // conditional typing: user should be typed in a way that matches the userType
    ? Student
    : T extends UserType.BOTH
    ? User & Student
    : User
> {
  const context = useContext(AppContext);
  if (userType !== UserType.BOTH && context.userType) {
    if ((userType ?? UserType.EMPLOYEE) !== context.userType) {
      throw new Error(
        `useAppContext: userType ${userType} does not match context.userType ${context.userType}`,
      );
    }
  }
  return context as AppContextState<
    T extends UserType.STUDENT
      ? Student
      : T extends UserType.BOTH
      ? User & Student
      : User
  >;
}
