import { saveAs } from 'file-saver';
import { TokenGeneratorSingleton } from 'auth/TokenGeneratorSingleton';
import { captureException } from '@sentry/nextjs';

export default async function authenticatedDownloader(
  url: string,
  filename?: string,
): Promise<Blob> {
  const fetchUrl = new URL(url);

  const accessToken =
    await TokenGeneratorSingleton.getInstance().tokenGenerator();
  const rawResponse = await fetch(fetchUrl.toString(), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!rawResponse.ok) {
    const errorInfo = await rawResponse.json();
    const error: any = new Error(
      errorInfo.response?.message ??
        'An error occurred while fetching the data.',
    );
    // Attach extra info to the error object.
    error.info = errorInfo;
    error.status = rawResponse.status;
    captureException(error, {
      extra: {
        requestUrl: url,
        method: 'GET',
        requestStatus: rawResponse.status,
      },
    });
    throw error;
  }
  const blob = await rawResponse.blob();

  if (filename) saveAs(blob, filename);
  return blob;
}
