import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicPopup } from '../components/InfoScreens/DynamicPopup';

export interface ErrorPopupInfo {
  title?: string | null;
  message: string | JSX.Element | null;
  icon?: JSX.Element;
  closeText?: string;
}

interface ErrorPopupContextState {
  error: ErrorPopupInfo;
  setError: (n: ErrorPopupInfo) => void;
  setErrorMessage: (n: string | JSX.Element | null) => void;
}

const ErrorPopupContext = createContext<ErrorPopupContextState>(undefined);

export function ErrorPopupWrapper({ children }) {
  const { t } = useTranslation('translation', { keyPrefix: 'errorPopup' });

  const [error, setError] = useState<ErrorPopupInfo>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!!error) setOpen(true);
  }, [error]);

  function setErrorMessage(m: string | JSX.Element | null) {
    setError({ message: m });
  }

  const sharedState: ErrorPopupContextState = {
    error,
    setError,
    setErrorMessage,
  };

  return (
    <ErrorPopupContext.Provider value={sharedState}>
      {children}
      <DynamicPopup
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title={t('titlePlaceholder')}
        {...error}
      />
    </ErrorPopupContext.Provider>
  );
}

export function useErrorPopupContext() {
  return useContext(ErrorPopupContext);
}
