import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import de from 'translations/de.json';
import en from 'translations/en.json';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      de: { translation: de },
      en: { translation: en },
    },
    fallbackLng: 'en',
    interpolation: {
      defaultVariables: {
        firstName: '',
        lastName: '',
      },
    },
  });

export default i18n;
