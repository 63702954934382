import { useTranslation } from 'react-i18next';

export enum ErrorType {
  ERRORTYPE_UNSPECIFIED,
  UNAUTHORIZED_ADMIN_PAGE,
  UNAUTHORIZED_ACCESS_RIGHT,
  UNAUTHORIZED,
  AUTH0_INITIALIZATION,
  UNAUTHORIZED_STUDENT_PAGE,
}

export function useErrorDetail(type: ErrorType) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'errorScreen',
  });

  const ErrorDetails: {
    [key in ErrorType]: { title?: string | null; message?: string | null };
  } = {
    [ErrorType.ERRORTYPE_UNSPECIFIED]: {
      title: t('unspecified.title'),
      message: t('unspecified.message'),
    },
    [ErrorType.UNAUTHORIZED_ADMIN_PAGE]: {
      title: t('unauthorizedAdminPage.title'),
      message: t('unauthorizedAdminPage.message'),
    },
    [ErrorType.UNAUTHORIZED_ACCESS_RIGHT]: {
      title: t('unauthorizedAccessRight.title'),
      message: t('unauthorizedAccessRight.message'),
    },
    [ErrorType.UNAUTHORIZED]: {
      title: t('unauthorized.title'),
      message: t('unauthorized.message'),
    },
    [ErrorType.UNAUTHORIZED_STUDENT_PAGE]: {
      title: t('unauthorizedStudentPage.title'),
      message: t('unauthorizedStudentPage.message'),
    },
    [ErrorType.AUTH0_INITIALIZATION]: {},
  };

  return ErrorDetails[type];
}

export function assert(condition: unknown, message: string) {
  if (!condition) {
    throw new Error(message || 'Assertion failed');
  }
}
