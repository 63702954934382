import { captureException } from '@sentry/nextjs';
import { Component, ReactNode } from 'react';
import { ErrorScreen } from '../InfoScreens/ErrorScreen';
import { ErrorType } from '../../utilities/errors';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<unknown, State> {
  constructor(props: unknown) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: unknown): State {
    return { hasError: !!error };
  }
  componentDidCatch(error: unknown, errorInfo: unknown): void {
    captureException(error, { extra: { errorInfo } });
  }
  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <ErrorScreen type={ErrorType.ERRORTYPE_UNSPECIFIED} logoutOnReturn />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
