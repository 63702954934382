export class TokenGeneratorSingleton {
  private static instance: TokenGeneratorSingleton;

  public tokenGenerator: (options?: any) => Promise<string>;

  private constructor() {}

  public static getInstance(): TokenGeneratorSingleton {
    if (!TokenGeneratorSingleton.instance) {
      TokenGeneratorSingleton.instance = new TokenGeneratorSingleton();
    }
    return TokenGeneratorSingleton.instance;
  }

  public setTokenGenerator(tokenGenerator: (options?: any) => Promise<string>) {
    this.tokenGenerator = tokenGenerator;
  }
}
