import { captureException } from '@sentry/nextjs';
import { TokenGeneratorSingleton } from '../auth/TokenGeneratorSingleton';
import { CustomError } from './customError';

type Method = 'GET' | 'DELETE';

export default async function authenticatedFetcher<T = any>(
  url: string,
  method: Method = 'GET',
): Promise<T | null> {
  const fetchUrl = new URL(url);

  const accessToken =
    await TokenGeneratorSingleton.getInstance().tokenGenerator();
  const rawResponse = await fetch(fetchUrl.toString(), {
    method: method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!rawResponse.ok) {
    const json = await rawResponse.json();
    const error = new CustomError(
      rawResponse.status,
      rawResponse.statusText,
      json.response.message ||
        `res.ok was falsy with status ${rawResponse.status} when requesting ${url} using method ${method}`,
      json,
    );
    captureException(error, {
      extra: {
        requestUrl: fetchUrl.toString(),
        method,
        requestStatus: rawResponse.status,
      },
    });
    throw error;
  }
  const responseText = await rawResponse.text();
  return responseText ? (JSON.parse(responseText) as T) : null;
}
