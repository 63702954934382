import { createContext, useContext, useEffect, useState } from 'react';
import { DynamicNotificationPopup } from '../components/InfoScreens/DynamicNotificationPopup';

export interface Notification {
  title: string;
  message?: string | JSX.Element | null;
  icon?: JSX.Element;
  maxDisplayTime?: number;
}

interface NotificationContextState {
  notification: Notification;
  setNotification: (n: Notification | null) => void;
}

const NotificationContext = createContext<NotificationContextState>(undefined);

export function NotificationWrapper({ children }) {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notification, setNotification] = useState<Notification | null>(null);
  const [notificationFadeTimeout, setNotificationFadeTimeout] =
    useState<NodeJS.Timeout>(null);

  useEffect(() => {
    setNotificationOpen(!!notification);
    if (notification) {
      clearTimeout(notificationFadeTimeout);
      setNotificationFadeTimeout(
        setTimeout(
          () => setNotificationOpen(false),
          notification.maxDisplayTime ?? 7000,
        ),
      );
    }
  }, [notification]);

  const sharedState: NotificationContextState = {
    notification,
    setNotification,
  };

  return (
    <NotificationContext.Provider value={sharedState}>
      {children}
      <DynamicNotificationPopup
        isVisible={notificationOpen}
        onHide={() => {
          setNotificationOpen(false);
        }}
        {...notification}
      />
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}
