import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  forwardRef,
  FunctionComponent,
  SVGProps,
  SyntheticEvent,
  useState,
} from 'react';

import { addBreadcrumb } from '@sentry/nextjs';
import { LoadingIndicator } from '../LoadingIndicator';
import { classNames } from 'utilities/classNames';

/**
 * Valid colors for a `Button`
 */
export type ButtonThemeColor =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'red'
  | 'clear'
  | 'light'
  | 'green';

export interface ButtonProps {
  onClick?: (e: SyntheticEvent) => void;
  color?: ButtonThemeColor;
  type?: 'button' | 'submit' | 'reset';
  padded?: boolean;
  tooltip?: string;
  disabled?: boolean;
  className?: string;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  block?: boolean;
  loading?: boolean;
  wide?: boolean;
  id?: string;
  ref?: React.Ref<HTMLButtonElement>;
}

export const commonButtonClassesWithoutPadding =
  'inline-flex rounded-lg text-[0.825rem] sm:text-sm items-center ease-in duration-100 select-none';
export const commonButtonClasses =
  commonButtonClassesWithoutPadding + ' px-2 sm:px-3 py-2';
export const primaryButtonClasses =
  ' border border-transparent text-white bg-accent-600 hover:bg-accent-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500 hover:shadow-lg hover:shadow-accent-300/50';
export const secondaryButtonClasses =
  'border border-transparent text-accent-600 bg-accent-100 hover:bg-accent-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500 shadow-sm hover:shadow-md hover:shadow-indigo-200/50';
export const whiteButtonClasses =
  'border-2 border-accent-600 text-accent-600 bg-white hover:bg-accent-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500';
export const redButtonClasses =
  'justify-center border border-transparent text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:shadow-lg hover:shadow-red-300/50';
export const greenButtonClasses =
  'justify-center border border-transparent text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:shadow-lg hover:shadow-green-300/50';
export const clearButtonClasses =
  'rounded-full border border-transparent text-sm font-medium text-gray-500 focus:outline-none hover:text-gray-800 hover:bg-gray-200 group';
export const disabledButtonClasses =
  'border-2 border-gray-500 text-sm font-medium text-gray-500 bg-gray-100 focus:outline-none cursor-not-allowed';
export const lightButtonClasses =
  'justify-center bg-white border border-gray-300 shadow-sm text-accent-600 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500';

/**
 * a button based on https://tailwindui.com/components/application-ui/elements/buttons
 */
export const Button: FunctionComponent<ButtonProps> = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      onClick,
      color,
      type,
      padded,
      tooltip,
      disabled,
      className,
      icon,
      children,
      block,
      loading,
      wide,
      ...props
    },
    ref,
  ) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const Icon = icon;

    function getColorClasses(): string {
      if (disabled) return disabledButtonClasses;
      switch (color) {
        case 'primary':
          return primaryButtonClasses;
        case 'secondary':
          return secondaryButtonClasses;
        case 'red':
          return redButtonClasses;
        case 'green':
          return greenButtonClasses;
        case 'clear':
          return clearButtonClasses;
        case 'light':
          return lightButtonClasses;
        default:
          return whiteButtonClasses;
      }
    }

    function getClassNames(): string {
      return classNames(commonButtonClasses, getColorClasses());
    }

    function getLoadingSpinnerColor(): string {
      if (disabled) return 'slate-500';
      if (!color) return 'accent-600';
      switch (color) {
        case 'clear':
          return 'accent-600';
        case 'secondary':
          return 'accent-600';
        case 'white':
          return 'accent-600';
        default:
          return 'white';
      }
    }

    return (
      <div
        className={classNames(
          !block && 'inline-flex relative',
          wide && 'w-full',
        )}
      >
        <button
          ref={ref}
          type={type ?? 'button'}
          onClick={(e) => {
            addBreadcrumb({
              category: 'button',
              message: `Button ${children} clicked`,
            });
            onClick?.(e);
          }}
          className={classNames(
            getClassNames(),
            padded && 'my-2',
            className,
            wide && 'w-full',
          )}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          disabled={disabled}
          {...props}
        >
          {Icon && !loading && (
            <Icon
              className={classNames(
                'h-[1.1rem] sm:h-5 w-[1.1rem] sm:w-5 flex-shrink-0',
                wide && 'mr-2',
              )}
              aria-hidden="true"
            />
          )}
          {loading && (
            <div className={wide ? 'mr-2' : ''}>
              <LoadingIndicator color={getLoadingSpinnerColor()} size={5} />
            </div>
          )}

          {children && color == 'clear' ? (
            <p
              className={classNames(
                'group-hover:inline-flex font-medium ease-in duration-100',
                icon && 'ml-2',
              )}
            >
              {children}
            </p>
          ) : (
            <p className={classNames('font-medium w-full', children && 'ml-1')}>
              {children}
            </p>
          )}
        </button>
        {tooltip && (
          <p
            className={classNames(
              'left-[50%] -translate-x-[50%] w-max text-xs font-normal bg-white absolute mt-10 rounded-md p-2 border-gray-300 border flex text-gray-600',
              showTooltip ? 'opacity-1' : 'opacity-0',
            )}
          >
            <InformationCircleIcon className="w-4 h-4 inline self-center mr-1" />
            {tooltip}
          </p>
        )}
      </div>
    );
  },
);

Button.defaultProps = {
  onClick: () => {
    return;
  },
};
Button.displayName = 'Button';
